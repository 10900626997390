export default {
  data () {
    return {
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      }
    }
  },
  methods: {
    loadDemands () {},
    getDemands () {},
    getColor (demand) {
      if (demand.firm) return 'green'
      else return 'orange'
    },
    getFirmText (demand) {
      if (demand.firm) return 'Závazná'
      else return 'Nezávazná'
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadDemands()
      }
    }
  }
}
